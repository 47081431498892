import React, { ComponentProps, ReactNode } from 'react';
import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { CancelIcon, H2, ITheme, Responsive, themed } from '@m2/blueprint-ui';
import { css } from '@emotion/core';
import { Color } from '../../../styles/Color';
import { getResponsiveImageUrl } from '../../../utils';

type IDialogOverlayProps = ComponentProps<typeof DialogOverlay>;

interface IDialogProps extends Omit<IDialogOverlayProps, 'title'> {
	children: ReactNode;
	ariaLabel: string;
	hasGradient?: boolean;
	onClickClose?: (
		event?: React.SyntheticEvent<Element, Event> | undefined,
	) => void;
	title?: string | ReactNode;
	backgroundImage?: string;
}

export const Dialog = ({
	children,
	ariaLabel,
	onDismiss,
	onClickClose,
	hasGradient,
	title,
	backgroundImage,
	...rest
}: IDialogProps) => (
	<StyledDialogOverlay onDismiss={onDismiss} {...rest}>
		<StyledDialogContent aria-label={ariaLabel}>
			<StyledTopBar backgroundImage={backgroundImage}>
				<StyledTitle styling={backgroundImage ? 'h2' : 'h3'}>
					{title}
				</StyledTitle>
				<StyledCancelButton
					onClick={onClickClose ?? onDismiss}
					title="close dialog"
					type="button"
				>
					<CancelIcon variation="brand" />
				</StyledCancelButton>
			</StyledTopBar>
			<StyledChildrenWrapper hasGradient={hasGradient}>
				{backgroundImage && <StyledTitle styling="h2">{title}</StyledTitle>}
				{children}
			</StyledChildrenWrapper>
		</StyledDialogContent>
	</StyledDialogOverlay>
);

export const StyledDialogOverlay = styled(DialogOverlay)`
	z-index: 6001;
	display: flex;
	align-items: center;
	justify-content: center;

	--h-padding: 40px;
	--v-padding: 40px;

	--topbar-content-color: ${Color.PrimaryOrange100};

	--topbar-padding: var(--v-padding) var(--h-padding);
	--dialog-children-padding: var(--v-padding) var(--h-padding);

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		height: 100dvh;

		--h-padding: 16px;
		--v-padding: 20px;
	}
`;

export const StyledTitle = styled(H2)`
	font-weight: 400;
	margin-bottom: 0.2em;
	font-style: normal;
	line-height: 30px;
	color: var(--topbar-content-color);
`;

export const StyledCancelButton = styled.button`
	cursor: pointer;
	appearance: none;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	background: transparent;
	outline: none;

	svg {
		display: block;
		width: 24px;
		height: 24px;
		margin: 8px;
		g {
			stroke: var(--topbar-content-color);
		}
	}
`;

const StyledTopBar = styled.div<{ backgroundImage?: string }, ITheme>`
	padding: var(--topbar-padding);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	${({ backgroundImage, theme }) =>
		backgroundImage
			? css`
					--topbar-content-color: ${theme.color.text.inverted};
					--bg-url: url(${getResponsiveImageUrl(`${backgroundImage}`, {
						w: 800,
						h: 400,
					})});
					background-image: linear-gradient(
							180deg,
							rgba(0, 0, 0, 0.75) 0%,
							rgba(0, 0, 0, 0) 100%
						),
						var(--bg-url);
					background-size: cover;
					background-position: center;
					height: 240px;

					@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
						height: 160px;
						justify-content: flex-end;

						--bg-url: url(${getResponsiveImageUrl(`${backgroundImage}`, {
							w: 1200,
							h: 300,
						})});

						${StyledTitle} {
							display: none;
						}
					}
			  `
			: css`
					padding-bottom: 0;
			  `}
`;

export const StyledChildrenWrapper = styled.div<
	{ hasGradient?: boolean },
	ITheme
>`
	padding: var(--dialog-children-padding);
	width: 100%;
	height: 100%;
	display: block;
	max-width: 100%;
	overflow-y: auto;
	scroll-behavior: smooth;
	flex-grow: 1;

	${StyledTitle} {
		display: none;
		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			display: block;
		}
	}

	${({ hasGradient }) =>
		hasGradient &&
		css`
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100px;
				pointer-events: none;
				background: linear-gradient(
					180deg,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255) 60%
				);

				@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
					height: 40px;
				}
			}
		`}
`;

export const StyledDialogContent = styled(DialogContent)`
	&[data-reach-dialog-content] {
		display: flex;
		padding: 0;
		max-height: 95vh;
		flex-direction: column;
		width: 630px;
		max-width: ${themed(({ layout }) => layout.main.maxWidth)};
		background: #fff;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		position: relative;

		@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
			width: 100vw;
			border-radius: 0;
			margin: 0;
			max-height: none;
			height: 100%;
		}
	}
`;
